import { production } from './.env';

const env_api = production.apiUrl || 'https://admin.goalempresas.com';
const env_img = production.imgUrl || 'https://admin.goalempresas.com';

export const environment = {
  production: true,
  apiUrl: env_api,
  imgUrl: env_img
};
